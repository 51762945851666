import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import { AnchorLink } from "gatsby-plugin-anchor-links"
//todo: larger fonts in numbers

const pub_list = [
  {
    citation: 'Marinescu, P. J., S. C. van den Heever, L. D. Grant, J. Bukowski and I. Singh (2024): How Much Convective Environment Subgrid Spatial Variability is Missing within Atmospheric Reanalysis Datasets? Geophys. Res. Lett, Submitted. ',
  },

  {
    citation: 'Schulte, R. M., R. J. Chase, B. Dolan, P. J. Marinescu, D. J. Posselt, K. L. Rasmussen, and S. C. van den Heever, 2024: Unclouding the correlations: A principal component analysis of convective environments. Geophysical Research Letters. In review.',
  },
  {
    citation: 'Sokolowsky, G.A., S.W. Freeman, W.K. Jones, J. Kukulies, F. Senf, P.J. Marinescu, M. Heikenfeld, K.N. Brunner, E.C. Bruning, S.C. Collis, R.C. Jackson, G.R. Leung, N. Pfeifer, B.A. Raut, S.M. Saleeby, P. Stier, and S.C. van den Heever, 2024: tobac v1.5: Introducing Fast 3D Tracking, Splits and Mergers, and Other Enhancements for Identifying and Analylzing Meteorological Phenomena. Geosci. Model Dev., 17, 5309-5330. DOI 10.5194/gmd-17-5309-2024.',
  },
  {
    citation: 'L. J. Leganes, S. Prasanth, Z. S. Haddad, F. J. Tapiador (2024)  “Nowcasting of Ice Water Path Fusing Passive Microwave and Infrared Satellite Data: A New Machine Learning Approach”. IEEE Transactions on Geoscience and Remote Sensing. In review.',
  },
  {
    citation: 'Chase, R., B. Dolan, K. L. Rasmussen, R. M. Schulte, G. Stephens, F. J. Turk, and S. C. van den Heever, 2024: A multi-frequency spaceborne radar perspective of deep convection. Journal of Applied Meteorology and Climatology, in review.',
  },
  {
    citation: 'Freeman, S.W., K. Brunner, W.K. Jones, J. Kukulies, F. Senf, P. Stier, and S.C. van den Heever, 2023: Advancing our Understanding of Cloud Processes and Their Role in the Earth System through Cloud Object Tracking. Available in EOR in Bull. Amer. Met. Soc. DOI 10.1175/BAMS-D-23-0204.1.',
  },


  {
    citation: 'Dolan, B., P. Kollias, S. C. van den Heever, K. L. Rasmussen, M. Oue, E. Luke, K. Lamer, B. P. Terserras, Z. Haddad, G. Stephens, and V. Chandrasekar, 2023: Time resolved reflectivity measurements of convective clouds. Geo. Res. Let. 50 (22), e2023GL105723.'
  },
  {
    citation: 'Prasanth, S., Z.S. Haddad, R.C., Sawaya, O.O. Sy, M. van den Heever, T. Narayana Rao, and S. Hristova-Veleva, 2023: Quantifying the vertical transport in convective storms using time sequences of radar reflectivity observations. Journal of Geophysical Research: Atmospheres, 128, e2022JD037701. DOI: 10.1029/2022JD037701.',
  },

  {
    citation: 'Takahashi, H., Z. J. Luo, G. L. Stephens, and J. Mulholland, 2023: Revisiting the Land-Ocean Contrasts in Deep Convective Cloud Intensity Using Global Satellite Observations, Geophys. Res. Lett. DOI: 10.1029/2022GL102089.'
  },

  {
    citation: 'Prasanth, S., Z. S. Haddad, O. O. Sy, and R. C. Sawaya, 2022: Derived Observations From Frequently Sampled Microwave Measurements of Precipitation—Part III: Convoys of mm-Wave Radiometers. IEEE Transactions on Geoscience and Remote Sensing, 60, pp.1-8.'
  }
]

const conf_proc_list = [

  {
    citation: 'Gupta, G., A. Mancini, P. Focardi, 2023: Preliminary Feed Assembly Design for INCUS, Proceedings of the Wireless Antenna and Microwave Symposium, Gandhinagar, India, June 7-10.'
  },

  {
    citation: 'Mancini, A., N. Chahat, S. Tanelli, P. Focardi, 2023: A Multi-Beam Ka-Band Deployable Mesh Reflector Antenna for the INCUS Mission, Proceedings of the 17th European Conference on Antennas and Propagation, Florence, Italy, March 26-31.'
  },
  {
    citation: 'Mancini, A., Q. Kostelecky, P. Focardi, 2023: Uncertainty Quantification Analysis for a Ka-band Deployable Mesh Reflector for INCUS, Proceedings of the Wireless Antenna and Microwave Symposium, Gandhinagar, India, June 7-10.'
  },
]



const pres_list = [
  {
    citation: 'van den Heever et al. 2024: The INCUS Mission, Three Radars and Radiometer on Small Satellites to Measure Convective Mass Flux from Space (given by Mary Morris). IGARSS, Athens, Greece, July 7-12 2024.',
    invited: false

  },

  {
    citation: 'Scarsi, F.E., A. Battaglia, R. Chase, D.J. Posselt, S. C. van den Heever, I. Singh, J. Bukowski, R. L. Storer, L. D. Grant, P. J. Marinescu, 2024: A Neural Network based algorithm for Multiple Scattering Onset identification in the INCUS reflectivity profiles. ESA ATMOS 2024 Bologna, Italy, July 1-5, 2024.',
    invited: false
  },

  {
    citation: 'van den Heever, S.C., 2024: The Ups and Downs of Tropical Convection. International Conference on Clouds and Precipitation Jeju, Korea – July 14-19, 2024.',
    invited: true
  },

  {
    citation: 'Marinescu, P.J., and co-authors, 2024: Cloud Processes Driving Updraft Variability on Different Timescales. International Conference on Clouds and Precipitation Jeju, Korea – July 14-19, 2024.',
    invited: false
  },
  {
    citation: 'Leung, G.R., and co-authors, 2024: Variability in Resolution Sensitivity of Updrafts and Surface Precipitation from Tropical Convection. International Conference on Clouds and Precipitation Jeju, Korea, July 14-19, 2024.',
    invited: false
  },
  {
    citation: 'van den Heever, S.C., and co-authors, 2024: The NASA INCUS Mission: Enhancing our Understanding of Convective Storm Dynamic, Precipitation and Anvil Processes. International Conference on Clouds and Precipitation Jeju, Korea, July 14-19, 2024.',
    invited: false
  },

  {
    citation: 'Prasanth, S., Z. Haddad, D.J. Posselt, P.J. Marinescu, and S.C. van den Heever, 2024: The Morphology and Modes of Evolution of Tropical Convective Updrafts over Convective Time-Scales. 36th AMS Conferences on Hurricanes and Tropical Meteorology. 6-10 May 2024, Long Beach, CA.',
    invited: false
  },


  {
    citation: 'Prasanth, S., Z. Haddad, D.J. Posselt, P.J. Marinescu, and S.C. van den Heever, 2024: The Morphology and Modes of Evolution of Tropical Convective Updrafts over Convective Time-Scales. 36th AMS Conferences on Hurricanes and Tropical Meteorology. 6-10 May 2024, Long Beach, CA.',
    invited: false
  },
  {
    citation: 'van den Heever, S.C., S.W. Freeman, S. Prasanth, R. Auth, J. Bukowski, Z. Haddad, P. Marinescu, D. Posselt, K. Rasmussen and I. Singh, 2024: The Use of Cloud Object Tracking in the INCUS Mission. Joint GEWEX-AOS-INCUS Convection Tracking Workshop, 23-25 April 2024, NASA GISS, NYC, NY.',
    invited: false
  },
  {
    citation: 'Freeman, S.W., C. Kwinta, R. Auth, S. Prasanth, J. Bukowski, R. Schulte, K. Rasmussen, and S.C. van den Heever, 2024: Connecting Convection with its Environment Using Databases of Tracked Storms. Joint GEWEX-AOS-INCUS Convection Tracking Workshop, 23-25 April 2024, NASA GISS, NYC, NY.',
    invited: false
  },

  {
    citation: 'van den Heever, S.C., Z. Haddad, J. Bukowski, R. Chase, B. Dolan, S. Freeman, L. Grant, M. Jensen, P. Kollias, G. Leung, P. Marinescu, D. Posselt, S. Prasanth, K. Rasmussen, R. Schulte, I. Singh, G. Stephens, R. Storer, and S. Tanelli, 2024: The NASA INCUS Mission – Understanding and Evaluating Convective Storm Dynamics through Tropics-Wide Observations of Convective Mass Flux. AMS Annual Meeting, 28 January - 1 February 2024, Baltimore, MD',
    invited: false
  },
  {
    citation: 'Posselt, D.J., and co-authors, 2024: Sensitivity of Spaceborne Radar and Microwave Radiometer Observations to Cloud Microphysical Properties in Deep Convection. AMS Annual Meeting, 28 January - 1 February 2024, Baltimore, MD.',
    invited: false
  },

  {
    citation: 'Haddad, Z.S., and co-authors, 2024: Deriving estimates of the storm-wide distribution of vertical air mass flux and wind speed w in convective storms from mm-wave radiometer observations and sporadic radar-derived estimates of w (as in the EarthCARE, AOS and INCUS missions). AMS Annual Meeting, 28 January - 1 February 2024, Baltimore, MD.',
    invited: false
  },
  {
    citation: 'Singh, I., and co-authors, 2024: The Effective Environmental Width of Convective Updrafts in the Tropics. AMS Annual Meeting, 28 January – 1 February 2024, Baltimore, MD.',
    invited: false
  },

  {
    citation: 'Singh, I., P. Marinescu, J. Bukowki, S.C. van den Heever, G. Leung, and L. Grant, 2024: Scales of Spatial Variability in Numerical Simulations of Tropical Convection. AMS Annual Meeting, 28 January – 1 February 2024, Baltimore, MD.',
    invited: false
  },
  {
    citation: 'Bukowski, J., P. Marinescu, I.T. Singh, L. Grant, G. Leung, and S.C. van den Heever, 2024: INCUS Simulation Database, Convective Mass Flux, and Anvils in the Tropics. AMS Annual Meeting, 28 January – 1 February 2024, Baltimore, MD.',
    invited: false
  },
  {
    citation: 'Schulte, R., and co-authors, 2023: Convective Environments from the Combined TRMM/GPM Record. AGU Annual Meeting, 11-15 December 2023, San Francisco, CA.',
    invited: false
  },
  {
    citation: 'Haddad, Z.S., and co-authors, 2023: Deriving estimates of the storm-wide distribution of vertical air mass flux and wind speed w in convective storms from mm-wave radiometer observations and sporadic radar-derived estimates of w (as in the EarthCARE, AOS and INCUS missions). AGU Annual Meeting, 11-15 December 2023, San Francisco, CA.',
    invited: false
  },

  {
    citation: 'van den Heever, S.C., Z. Haddad, J. Bukowski, R. Chase, B. Dolan, S. Freeman, L. Grant, P. Kollias, G. Leung, P. Marinescu, D. Posselt, S. Prasanth, K. Rasmussen, R. Schulte, I. Singh, G. Stephens, and R. Storer, 2023: Understanding Tropical Storm Dynamics through the Lens of the NASA INCUS Mission. AGU Annual Meeting, 11-15 December 2023, San Francisco, CA.',
    invited: false
  },

  {
    citation: 'Prasanth, S., and co-authors, 2023: Quantifying the dependence of moist convective intensity to a pair of Ka-band radar measurements. AGU Annual Meeting, 11-15 December 2023, San Francisco, CA.',
    invited: false
  },

  {
    citation: 'Dolan, B., S. C. van den Heever, K. L. Rasmussen, L. Grant, Z. Haddad, R. Chase, R. Schulte, P. Marinescu, P. Kollias, D. Posselt, and V. Chandrasekar, 2023: Relating radar reflectivity time-lapse to vertical momentum using ground-based radar observations. AGU Fall Meeting, San Francisco, CA Dec. 11-15 2023. (Oral)',
    invited: false
  },

  {
    citation: 'van den Heever, S.C., and co-authors, 2023: Chasing Tropical Convective Storms with the INCUS Mission. Gal-Chen Lecture Award, University of Oklahoma, 2 November 2023. ',
    invited: true
  },

  {
    citation: 'van den Heever, S.C., and co-authors, 2023: Chasing Tropical Convective Storms with the INCUS Mission. EAPS Seminar Series, MIT, 11 October 2023, Boston. MA.',
    invited: true
  },
  {
    citation: 'van den Heever, S.C., and co-authors, 2023: Observing Convective Storms in the Tropics through the Lens of the NASA INCUS Mission. Tendeg Seminar, 26 September 2023, Louisville, CO. ',
    invited: true
  },
  {
    citation: 'van den Heever. S.C., and co-authors, 2023: Observing Convective Storms in the Tropics through the Lens of the NASA INCUS Mission. GESTAR II Seminar Series, 14 September 2023, Virtual.',
    invited: true
  },

  {
    citation: 'van den Heever, S.C., and co-authors, 2023: Tropical Convection through the Lens of the INCUS Mission. Keynote Presentation, 40th Conference on Radar Meteorology, 28 August – 01 September 2023, Minneapolis, MN.',
    invited: true
  },
  {
    citation: 'Posselt D.J., and co-authors, 2023: Overview of the INCUS radar and microwave forward models. 40th Conference on Radar Meteorology, 28 August – 01 September 2023, Minneapolis, MN. ',
    invited: false
  },
  {
    citation: 'Posselt D.J., and co-authors, 2023: Sensitivity of forward modeled radar to changes in ice microphysical assumptions. 40th Conference on Radar Meteorology, 28 August – 01 September 2023, Minneapolis, MN. ',
    invited: false
  },


  {
    citation: 'Chase, R., B. Dolan, P. Kollias, K. Rasmussen, G.L. Stephens, and S.C. van den Heever, 2023: GPM KaPR Deep Convection Observations: Insight for Future Spaceborne Radar Missions. 40th Conference on Radar Meteorology, 28 August – 01 September 2023, Minneapolis, MN.',
    invited: false
  },


  {
    citation: 'Dolan, B., J. Bukowski, R. Chase, L. Grant, Z. Haddad, P. Kollis, P. Marinescu, M. Oue, D. Posselt, K. L. Rasmussen, R. Schulte, IT Singh, and R. Storer, and S. C. van den Heever 2023: Quantitative analysis of the dZ/dT method using ground radar observations, 40th AMS Conference on Radar Meteorology, Minnesota, MN, 28 August – 1 Sept. (Oral)',
    invited: false
  },
  {
    citation: 'Dolan, B., J. Bukowski, R. Chase, L.D. Grant, Z. Haddad, P. Kollias, P.J. Marinescu, M. Oue, D.J. Posselt, K.L. Rasmussen, R. Schulte, I. Singh, R. Storer, and S.C. van den Heever, 2023: Quantitative Analysis of the Delta-t Approach for Estimating Convective Mass Flux using Ground Radar Observations. 40th Conference on Radar Meteorology, 28 August – 01 September 2023, Minneapolis, MN.',
    invited: false
  },
  {
    citation: 'Tanelli, S., Z. Haddad, and S.C. van den Heever, 2023: Investigation of Convective Updrafts (INCUS): Status after Phase A. SPIE, 20-24 August 2023, San Diego, CA.',
    invited: false
  },

  {
    citation: 'Marinescu, P.J., S. C. van den Heever, J. Bukowski, L. D. Grant, D. J. Posselt, and I. Singh, 2023: The Covariability of Microphysical and Dynamical Processes within Convective Updrafts in Different Environments and Storm Types. 20th AMS Conference on Mesoscale Processes, AMS, 17-21 July 2023, Madison, WI.',
    invited: false
  },

  {
    citation: 'van den Heever, S.C., Z. Haddad, J. Bukowski, R. Chase, B. Dolan, S. Freeman, L. Grant, P. Kollias, G. Leung, J. Luo, P. Marinescu, M. Morris, D. Posselt, K. Rasmussen, S. Prasanth, R. Schulte, I. Singh, G. Stephens, R. Storer, O. Sy, H. Takahashi, S. Tanelli, and Z. Xu, 2023: Enhancing our Understanding of Tropical Convective Processes through the INCUS Mission. 20th AMS Conference on Mesoscale Processes. 17-21 July 2023, Madison, WI.',
    invited: false
  },

  {
    citation: 'van den Heever, S.C., and co-authors, 2023: Studying Convective Storms and Extreme Weather with the NASA INCUS Mission. RadMet23, 6 July 2023, Bologne, Italy.',
    invited: true
  },
  {
    citation: 'van den Heever, S.C., and co-authors, 2023: The INCUS Mission: Measuring Convective Mass Flux from Space. 14th International Conference on Precipitation, ICP, 5-9 June 2023, Norman, OK (given by Kristen Rasmussen). ',
    invited: true
  },

  {
    citation: 'Marinescu, P. J., Susan van den Heever, Jennie Bukowski, Leah Grant, Itinderjot Singh, Sean W. Freeman, Gabrielle Leung, and Derek Posselt, 2023: Overview of INCUS LES Simulations. AOS Modeling and DA Working Group. Virtual. May 2023.',
    invited: false
  },


  {
    citation: 'van den Heever, S.C., and co-authors, 2023: Chasing Tropical Convective Storms with the INCUS Mission. Departmental Seminar. 25 May 2023, University of Reading, Reading. ',
    invited: true
  },

  {
    citation: 'van den Heever, S.C., and co-authors, 2023: Tropical Convection through the Lens of the INCUS Mission. European Geophysical Union General Assembly, 26 April 2023, In-Person',
    invited: false
  },
  {
    citation: 'Posselt, D.J., 2023: The relative sensitivity of convective simulations to perturbations in initial conditions and microphysics parameters. European Geophysical Union General Assembly, 26 April 2023, In-Person',
    invited: false
  },

  {
    citation: 'Haddad, Z., S. Prasanth, S.C. van den Heever, P. Marinescu, S. Freeman, and D. Posselt, 2023: Parametrizing the evolution of convective updraft vertical velocities. European Geophysical Union General Assembly, 26 April 2023, In-Person',
    invited: false
  },
  {
    citation: 'Freeman, S.W., R. Schulte, G. Leung, S.C. van den Heever, 2023: Tracking Convective Storms and their Environments with the tobac Tracking Package. European Geophysical Union General Assembly, 26 April 2023, In-Person',
    invited: false
  },
  {
    citation: 'Donitz, Benjamin, et. al. (April 16 – 21, 2023). INCUS Mission Architecture and Systems Engineering Approach. COSPAR 2023, Singapore.',
    invited: false
  },
  {
    citation: 'Singh, I., and co-authors, 2023: Using tobac to Identify Updrafts and Thermals in Large Eddy Simulations of Deep Convection. Cloud Tracking Workshop, Oxford, UK, April 16-21, 2023',
    invited: false
  },
  {
    citation: 'Freeman, S., and co-authors, 2023: Tracking Convective Storms and their Environments with tobac. Cloud Tracking Workshop, Oxford, UK, April 16-21, 2023.',
    invited: false
  },
  {
    citation: 'Freeman, S.W., and co-authors, 2023: tobac v1.5: Rapid Tracking of Cloud Systems within Large 2D and 3D Datasets. Cloud Tracking Workshop, Oxford, UK, April 16-21, 2023.',
    invited: true
  },

  {
    citation: 'Marinescu, P., and co-authors, 2023: Automated feature tracking of convective systems in models and observations in support of NASA’s INCUS mission. Cloud Tracking Workshop, Oxford, UK, April 16-21, 2023.',
    invited: false
  },


  {
    citation: 'van den Heever, S.C., and co-authors, 2023: The INCUS Mission. Seminar, NextGEMS Storms and Science Hour, 5 April 2023, Virtual.',
    invited: true
  },
  {
    citation: 'Marinescu, P. J., S. C. van den Heever, Z. Haddad, S. W. Freeman, D. J. Posselt, L. D. Grant, and S. Prasanth, 2023: The Temporal Variability of Cloud Properties in Tropical, Convective Clouds. 103rd American Meteorological Society (AMS) Annual Meeting. 8-12 January 2023, Denver, CO.',
    invited: false
  },
  {
    citation: 'Marinescu, P.J., S. C. van den Heever, Z. S. Haddad, S. W. Freeman, D. J. Posselt, L. D. Grant, and S. Prasanth, 2023: The Temporal Variability of Cloud Properties in Tropical, Convective Clouds. 3rd Symposium on Mesoscale Processes, 103rd AMS Annual Meeting, AMS, 8-12 January 2023, Denver, CO.',
    invited: false
  },
  {
    citation: 'Kollias, P., A. Battaglia, S.A. Braun, and S.C. van den Heever, 2022: What knowledge gaps related to convective cloud-scale dynamics and microphysics should we expect to fill with the new spaceborne missions? AGU Fall Meeting, 12-16 December 2022, Chicago, IL. ',
    invited: false
  },

  {
    citation: 'Schulte, R., S.C. van den Heever, D.J. Posselt, S.W. Freeman, R. Beauchamp, P.J. Marinescu, and R.L. Storer, 2022: The Effects of Instrument Noise, Forward Model Uncertainties, and Footprint Overlap on Simulated Measurements from INCUS. American Geophysical Union Fall Meeting, AGU, 12-16 December 2022, Chicago, IL.',
    invited: false
  },
  {
    citation: 'Dolan, B., P. Kollias, S.C. van den Heever, K.L. Rasmussen, E.P. Luke, and B. Treserras, 2022: High-Resolution C-band Radar Observations of Convective Cores. American Geophysical Union Fall Meeting, AGU, 12-16 December 2022, Chicago, IL.',
    invited: false
  },
  {
    citation: 'Sai, P., Z.S. Haddad, S.W. Freeman, P.J. Marinescu, and S.C. van den Heever, 2022: Quantifying the Nonstationarity of the Vertical Mass Transport at Convective Time Scales. American Geophysical Union Fall Meeting, AGU, 12-16 December 2022, Chicago, IL.',
    invited: false
  },

  {
    citation: 'van den Heever, S.C., Z.S. Haddad, S. Tanelli, G.L. Stephens, D.J. Posselt, Y. Kim, K. Rasmussen, S.T. Brown, S.A. Braun, B. Dolan, S.W. Freeman, L.D. Grant, P. Kollias, Z. Luo, G.G. Mace, P.J. Marinescu, S. Padmanabhan, P. Partain, W.A. Petersen, S. Prasanth, S.C. Reising, R. Schulte, C. Schumacher, R.L. Storer, and O. Sy, 2022: The NASA INCUS Mission. American Geophysical Union Fall Meeting, AGU, 12-16 December 2022, Chicago, IL.',
    invited: false
  },

  {
    citation:
      'Kollias, P., A. Battaglia, S. A. Braun, and S. C. van den Heever, 2022: What knowledge gaps related to convective cloud-scale dynamics and microphysics should we expect to fill with the new spaceborne missions? AGU Fall Meeting, 12-16 December 2022, Chicago, IL.',
    invited: false
  },
  {
    citation: 'Dolan, B., P. Kollias, S. C. van den Heever, K. L. Rasmussen, E. P. Luke, and B. P. Treserras, 2022: High-Resolution C-band Radar Observations of Convective Cores. AGU Fall Meeting, 12-16 December 2022, Chicago, IL.',
    invited: false
  },
  {
    citation: 'Prasanth, S., Z. S. Haddad, S. W. Freeman, P. J. Marinescu, and S. C. van den Heever, 2022: Quantifying the Nonstationarity of the Vertical Mass Transport at Convective Time Scales. AGU Fall Meeting, 12-16 December 2022, Chicago, IL.',
    invited: false
  },
  {
    citation: 'Schulte, R., S. C. van den Heever, D. J. Posselt, S. W. Freeman, R. Beauchamp, P. J. Marinescu, and R. L. Storer, 2022: The Effects of Instrument Noise, Forward Model Uncertainties, and Footprint Overlap on Simulated Measurements from INCUS. AGU Fall Meeting, 12-16 December 2022,Chicago, IL.',
    invited: false
  },
  {
    citation: 'van den Heever, S. C., 2022: Data, Data and More Data – An Atmospheric Scientist’s Perspective of the Pros and Cons of the Use of Commercialized SmallSats and Instrumentation in Future Science Missions. AGU Fall Meeting, 12-16 December 2022, Chicago, IL.',
    invited: true
  },
  {
    citation: 'van den Heever, S. C., and co-authors, 2022: The NASA INCUS Mission. AGU Fall Meeting, 12-16 December 2022, Chicago, IL.',
    invited: false
  },
  {
    citation: 'Takahashi, H., 2022: Geo-Ring Applications: Convective System Tracking. ICWG ISCCP-NG Topical Group Meeting, 5 December, 2022',
    invited: false
  },

  {
    citation: 'van den Heever, S. C., and the INCUS science team, 2022: INCUS – Investigation of Convective Updrafts. INCUS Science Team Meeting, 11-13 October, Fort Collins, CO.',
    invited: false
  },

  {
    citation: 'van den Heever, S.C., and the INCUS science team, 2022: INCUS – Investigation of Convective Updrafts. INCUS Science Team Meeting Dinner Reception, 11 October, Fort Collins, CO.',
    invited: false
  },

  {
    citation: 'van den Heever, S. C., and the INCUS team, 2022: INCUS. Plenary Talk. 30 September 2022, Oxford University, Oxford.',
    invited: true
  },
  {
    citation: 'van den Heever, S. C., Z. S. Haddad, S. Tanelli, G. L. Stephens, D. J. Posselt, Y. Kim, K. L. Rasmussen, S. T. Brown, S. A. Braun, B. Dolan, S. W. Freeman, L. D. Grant, P. Kollias, Z. Luo, G. G. Mace, P. J. Marinescu, S. Padmanabhan, P. Partain, W. A. Petersen, S. Prasanth, S. C. Reising, R. Schulte, C. J. Schumacher, R. Storer, and O. Sy: The NASA INCUS Mission, The 16th Conference on Cloud Physics Remote Sensing of Clouds, AMS Collective Madison Meeting, 8-12 August 2022, Madison, Wisconsin.',
    invited: false
  },
  {
    citation: 'Posselt, D.J., S. C. van den Heever, Z. S. Haddad, K. L. Rasmussen, G. L. Stephens, S. Tanelli, S. Padmanabhan, and Y. Kim: The Investigation of Convective Updrafts (INCUS) Mission: Studying Convective Motions with a Convoy of SmallSats, The 15th Satellite Meteorology Conference, AMS Collective Madison Meeting, 8-12 August 2022, Madison, Wisconsin.',
    invited: false
  },
  {
    citation: 'Posselt, D.J., S. C. Van Den Heever, M. Minamide, L. D. Grant, R. Storer, S. W. Freeman, P. J. Marinescu, and K. L. Rasmussen: Examining Convection-Environment Interactions and Designing Next Generation Convection Observing Systems using Large Ensembles of Convection Resolving Simulations, The 25th Conference on Satellite Meteorology, Oceanography, and Climatology/Joint 2022 NOAA Satellite Conference SmallSats, CubeSats and Constellations for Satellite Meteorology, AMS Collective Madison Meeting, 8-12 August 2022, Madison, Wisconsin.',
    invited: false
  },


  {
    citation: 'van den Heever, S. C., Z. Haddad, S. Tanelli, G. L. Stephens, D. Posselt, and co-authors, 2022: The INCUS Mission. 10th Workshop of the International Precipitation Working Group, 13-17 June 2022, CIRA, Fort Collins, CO.',
    invited: false
  },

  {
    citation: 'van den Heever, S. C., Z. S. Haddad, S. Tanelli, G. L. Stephens, D. J. Posselt, Y. Kim, K. L. Rasmussen, S. T. Brown, P. Partain, S. A. Braun, B. Kahn, P. Kollias, Z. Luo, W. A. Petersen, and C. J. Schumacher, 2022: The NASA INCUS Mission. The INCUS Mission. The Living Planet Symposium, ESA, 23-27 May 2022, Bonn, Germany.',
    invited: false
  },
  {
    citation: 'van den Heever, S. C., Z. Haddad, S. Tanelli, G. Stephens, D. Posselt, Y. Kim, S. Brown, S. Braun, L. Grant, P. Kollias, Z. Luo, G. Mace, P. Marinescu, S. Padmanabhan, P. Partain, W. Petersen, S. Prasanth, K. Rasmussen, S. Reising, and C. Schumacher and the INCUS Mission team. The INCUS Mission. EGU, 23-27 May 2022, Vienna, Austria.',
    invited: false
  },
  {
    citation:
      'Haddad, Z., R. Sawaya, S. Prasanth, M. van den Heever, O. Sy, S. van den Heever, L. Grant, T. N. Rao, G. Stephens, S. Hristova-Veleva, D. Posselt and R. Storer, 2022: Observation strategy of the INCUS mission: retrieving vertical mass flux in convective storms from low-earth-orbit convois of miniaturized microwave instruments. EGU, 23-27 May 2022, Vienna, Austria.',
    invited: false
  },
  {
    citation: 'Tanelli, S., Z. S. Haddad, S. C. van den Heever, S. Brown, and S. C. Reising, 2022: INCUS as RainCube and TEMPEST-D Legacy. 4S Symposium, CNES/ESA, 16-20 May 2022, Vilamoura, Portugal.',
    invited: false
  },
  {
    citation: 'van den Heever, S. C., 2022: The INCUS Mission. Department Seminar Series, 24 February 2022, Department of Atmospheric Science, Colorado State University, Fort Collins, CO.',
    invited: true
  }



]

const pubs_render = pub_list.map((item) =>
  <li>{item.citation}</li>
);

const conf_proc_render = conf_proc_list.map((item) =>
  <li>{item.citation}</li>
);


const pres_render = pres_list.map((item) => {
  if (item.invited == false)
    return <li>{item.citation}</li>
  else
    return <li>{item.citation} <strong style={{ fontWeight: 900, fontSize: "110%" }}>(Invited)</strong></li>
}
);


const Pub_Pres_Page = () => {
  return (
    <Layout pageTitle="INCUS Publications and Presentations">
      <h1>INCUS Publications and Presentations</h1>
      <AnchorLink to="/publications#presentations" title="Jump to Presentations">
        <h5>Jump to presentations</h5>
      </AnchorLink>
      <h2>INCUS Publications</h2>
      <ul>
        {pubs_render}
      </ul>
      <h2>INCUS Conference Proceedings</h2>
      <ul>
        {conf_proc_render}
      </ul>

      <h2 id="presentations">INCUS Presentations</h2>
      <ul>
        {pres_render}
      </ul>



    </Layout>

  )
}

export default Pub_Pres_Page

